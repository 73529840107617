.breadcrumbs-container {
  padding: 10px 16px;
  background: #f8f9fa;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.breadcrumb-link {
  text-decoration: none;
  color: #007bff;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.breadcrumb-current {
  font-weight: 600;
  color: #333;
}
