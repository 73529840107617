.org-node-container {
    position: relative;
    padding: 15px;
    border-radius: 10px;
    background: linear-gradient(135deg, #a5b5c6, #ecf0f5);
    text-align: center;
    cursor: pointer;
    display: inline-block;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  .org-node-container:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 15px rgba(251, 251, 251, 0.3);
  }
  
  .org-title {
    font-weight: bold;
    font-size: 14px;
  }
  
  .org-name {
    margin-top: 5px;
    font-size: 12px;
  }
  
  .org-email {
    font-size: 10px;
    color: #546883;
  }

  .org-manager {
    font-size: 14px;
    color: #0b4187;
  }
  
  .expand-icon {
    margin-top: 40px;
    font-size: 40px;
    color: #546883;
  }
  
  /* Animations */
  .org-node-container .org-details {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .org-node-container:hover .org-details {
    opacity: 1;
    transform: translateY(0);
  }
  